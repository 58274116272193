/* CookiePolicy.css */

.cookie-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    position: relative;
    top: 60px;
    /* Adjust based on your navbar height */
}

/* Common styles for all sections */
.cookie-policy-container div {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Specific section styles */
.cookie1 h1,
.cookie2 h1,
.cookie3 h1,
.cookie4 h1 {
    color: #333;
    margin-top: 0;
}

.cookie1 p,
.cookie2 p,
.cookie3 p,
.cookie4 p {
    color: #666;
    line-height: 1.6;
}

.cookie3 ul {
    list-style-type: disc;
    padding-left: 20px;
}

.cookie3 li {
    margin-bottom: 10px;
}

.cookie-policy-container a {
    color: #1e90ff;
    text-decoration: none;
}

.cookie-policy-container a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .cookie-policy-container {
        padding: 10px;
    }

    .cookie-policy-container div {
        padding: 10px;
    }

    .cookie-policy-container h1 {
        font-size: 1.5em;
    }

    .cookie-policy-container p,
    .cookie-policy-container ul {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .cookie-policy-container {
        padding: 5px;
    }

    .cookie-policy-container div {
        padding: 5px;   
    }

    .cookie-policy-container h1 {
        font-size: 1.2em;
    }

    .cookie-policy-container p,
    .cookie-policy-container ul {
        font-size: 0.8em;
    }
}