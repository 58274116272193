/* PrivacyPolicy.css */

.privacy-container {
    max-width: 800px;
    margin: 80px auto 20px auto;
    /* Ensure it's below the navbar */
    padding: 30px;
    /* Increased padding for better spacing */
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.95);
    /* Slightly more opaque */
    border-radius: 10px;
    /* Increased border radius for smoother corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Slightly larger shadow for depth */
}

.privacy-container h1,
.privacy-container h2 {
    color: #2c3e50;
    /* Darker color for headings */
    margin-top: 20px;
    font-family: 'Georgia', serif;
    /* Different font for headings */
}

.privacy-container h1 {
    border-bottom: 2px solid #3498db;
    /* Add a bottom border to the main heading */
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.privacy-container p,
.privacy-container ul {
    color: #34495e;
    /* Darker text color for better readability */
    line-height: 1.8;
    /* Increased line height for better readability */
}

.privacy-container ul {
    list-style-type: disc;
    padding-left: 40px;
    /* Increased padding for better list indentation */
}

.privacy-container li {
    margin-bottom: 15px;
    /* Increased margin for better spacing between list items */
}

.privacy-container a {
    color: #2980b9;
    /* Link color */
    text-decoration: none;
    /* Remove underline */
}

.privacy-container a:hover {
    text-decoration: underline;
    /* Underline on hover */
}

@media (max-width: 768px) {
    .privacy-container {
        padding: 20px;
        /* Reduced padding for smaller screens */
    }

    .privacy-container h1 {
        font-size: 1.8em;
        /* Adjusted font size for better responsiveness */
    }

    .privacy-container h2 {
        font-size: 1.5em;
        /* Adjusted font size for better responsiveness */
    }

    .privacy-container p,
    .privacy-container ul {
        font-size: 1em;
        /* Adjusted font size for better responsiveness */
    }
}

@media (max-width: 480px) {
    .privacy-container {
        padding: 15px;
        /* Further reduced padding for very small screens */
    }

    .privacy-container h1 {
        font-size: 1.5em;
        /* Further adjusted font size */
    }

    .privacy-container h2 {
        font-size: 1.2em;
        /* Further adjusted font size */
    }

    .privacy-container p,
    .privacy-container ul {
        font-size: 0.9em;
        /* Further adjusted font size */
    }
}