/* Filename: Projects.css */

.projects-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    background-color: #f4f4f4;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    max-width: 1200px;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
}

.side-image {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-details {
    flex: 2;
    padding: 20px;
    font-family: 'Lato', sans-serif;
}

.project-details h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    color: #3e8aad;
    margin-bottom: 20px;
}

.project-details p {
    text-align: left;
    font-size: 1.0em;
    line-height: 1.6;
    color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
    .projects-container {
        flex-direction: column;
        padding: 20px;
    }

    .image-container,
    .project-details {
        flex: none;
        max-width: 100%;
        padding: 10px;
    }

    .project-details h1 {
        font-size: 1.8em;
    }

    .project-details p {
        font-size: 1em;
    }
}