/* Filename: Contact.css */

.contact-us-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background: linear-gradient(135deg, #3897c4 0%, #3a8ece 100%);
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(5, 0, 65, 0.1);
    text-align: center;
    top: 40px;

    font-family: Arial, sans-serif;
    box-sizing: border-box;
    position: relative;
}

.contact-us-container h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

.thank-you-message {
    text-align: center;
    font-family: 'Lato', sans-serif;
    color: #ffffff;
}



.thank-you-message h2 {
    font-size: 2em;
    color: #ffffff;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group label {
    display: block;
    font-family: 'Lato', sans-serif;
    font-size: 1.2em;
    color: #333;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #3e8aad;
}

.form-group textarea {
    height: 150px;
    resize: vertical;
}

button {
    display: inline-block;
    padding: 12px 30px;
    font-size: 1.2em;
    color: white;
    background-color: #02161f;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-family: 'Montserrat', sans-serif;
    margin-top: 20px;
}

button:hover {
    background-color: #dd7805;
    transform: scale(1.05);
}

button:active {
    transform: scale(1);
}

@media (max-width: 768px) {
    .contact-us-container {
        padding: 20px;
    }

    button {
        padding: 10px;
        font-size: 1em;
    }
}