/* TermsAndConditions.css */

.terms-container {
    max-width: 800px;
    margin: 80px auto 20px auto;
    /* Ensure it's below the navbar */
    padding: 20px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.terms-container h1,
.terms-container h2 {
    color: #333;
    margin-top: 20px;
}

.terms-container h1 {
    border-bottom: 2px solid #3498db;
    /* Add a bottom border to the main heading */
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.terms-container p,
.terms-container ul {
    color: #666;
    line-height: 1.6;
}

.terms-container ul {
    list-style-type: disc;
    padding-left: 20px;
}

.terms-container li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .terms-container {
        padding: 10px;
    }

    .terms-container h1 {
        font-size: 1.5em;
    }

    .terms-container h2 {
        font-size: 1.2em;
    }

    .terms-container p,
    .terms-container ul {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .terms-container {
        padding: 5px;
    }

    .terms-container h1 {
        font-size: 1.2em;
    }

    .terms-container h2 {
        font-size: 1em;
    }

    .terms-container p,
    .terms-container ul {
        font-size: 0.8em;
    }
}