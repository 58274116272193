/* General styles for navbar */
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #b0b3b4;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 20px;
}

.nav__logo {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.nav__logo img {
    height: 60px;
    width: auto;
}

.nav__actions {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav__toggle,
.nav__close {
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    display: none;
}

.nav__cta {
    background-color: #e68f0d;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.nav__cta:hover {
    background-color: #271909;
}

.nav__list {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav__link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav__link:hover {
    color: #ffdd57;
}

/* Medium screens (e.g., 480px) */
@media screen and (max-width: 768px) {
    .nav__toggle {
        display: block;
    }

    .nav__menu {
        position: fixed;
        top: 0;
        right: -100%;
        width: 80%;
        height: 100%;
        background-color: rgba(5, 150, 218, 0.9);
        backdrop-filter: blur(10px);
        padding: 5rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: right 0.3s ease;
    }

    .nav__menu.show-menu {
        right: 0;
    }

    .nav__list {
        flex-direction: column;
        gap: 1.5rem;
    }

    .nav__close {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    .nav__cta {
        background-color: #e68f0d;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        text-decoration: none;
        transition: background-color 0.3s ease;
    }

    .nav__cta:hover {
        background-color: #271909;
    }

    .nav__actions {
        gap: 10px;
    }

    .nav__cta {
        margin-left: auto;
    }
}

/* Small screens (like Samsung S20) */
@media screen and (max-width: 480px) {
    .nav {
        padding: 0 10px;
    }

    .nav__logo img {
        height: 50px;
    }

    .nav__cta {
        font-size: 0.9rem;
        padding: 0.4rem 0.8rem;
    }

    .nav__toggle {
        font-size: 1.3rem;
    }
}