/* src/components/CardPayment.css */

.card-payment-container {
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 50px auto;
    text-align: center;
}

.card-payment-container h1 {
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
}

.card-payment-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card-payment-container label {
    font-size: 16px;
    color: #555;
}

.card-payment-container input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.card-payment-container button {
    padding: 15px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.card-payment-container button:hover {
    background-color: #0056b3;
}