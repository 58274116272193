/* Donate.css */

.donate-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    background-color: white;
    /* Ensure a clear background */
}

.donate-container h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
}

.donate-container h2 {
    display: inline-flex;
    background: linear-gradient(135deg, #3a86dd 0%, #4364F7 100%);
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(16, 82, 121, 0.1);
    font-family: 'Arial', sans-serif;
    background-size: contain;
}

.donate-container p {
    font-family: 'Lato', sans-serif;
    margin-bottom: 20px;
    /* Adjust as needed */
}

.donate-section {
    display: flex;
    flex-direction: column;
    /* Change to column by default */
    gap: 20px;
}

.donate-form,
.donate-options {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.donate-form h2,
.donate-options h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
}

.donate-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.donate-form label {
    font-family: 'Lato', sans-serif;
    text-align: left;
}

.donate-form input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.donate-form button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #3e8aad;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.donate-form button:hover {
    background-color: #2a6b89;
}

.donate-options ul {
    list-style-type: none;
    padding: 0;
}

.donate-options li {
    font-family: 'Lato', sans-serif;
    margin-bottom: 10px;
    text-align: left;
}

.donate-options a {
    color: #3e8aad;
    text-decoration: none;
}

.donate-options a:hover {
    text-decoration: underline;
}

/* Media query for larger screens */
@media (min-width: 769px) {
    .donate-section {
        flex-direction: row;
        /* Change to row on larger screens */
        justify-content: space-between;
        gap: 20px;
    }

    .donate-form,
    .donate-options {
        min-width: 300px;
    }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .donate-section {
        flex-direction: column;
        align-items: center;
    }

    .donate-form,
    .donate-options {
        width: 100%;
        min-width: unset;
    }
}