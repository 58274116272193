/* Filename: styles.css */


body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.overlay-text {
    font-family: 'Lato', sans-serif;
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    font-size: 2em;
    font-weight: 1000;
    border-radius: 5px;
}

.overlay-text1 {
    font-family: 'Lato', sans-serif;
    color: rgb(245, 83, 7);
    padding: 10px 20px;
    font-size: 0.5em;
    font-weight: 700;
    border-radius: 5px;
}

.container {
    max-width: 1200px;
    /* Maximum width for large screens */
    margin: 0 auto;
    /* Center the container */
    padding: 0 15px;
    /* Add some padding for small screens */
}

.container4 {
    max-width: 1500px;
    /* Maximum width for large screens */
    margin: 0 auto;
    /* Center the container */
    padding: 0 15px;
    /* Add some padding for small screens */
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
        /* Full width on small screens */
        padding: 0 10px;
        /* Adjust padding for small screens */
    }
}