/* Filename: Home.css */

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home {
    font-family: 'Lato', sans-serif;
    color: #333;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.hero {
    position: relative;
    text-align: center;
    color: white;
}

.full-width-image {
    width: 100%;
    height: auto;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
}

.about,
.objectives {
    margin: 40px 0;
}

.objectives h1 {
    text-align: center;
    margin-bottom: 20px;
}

.objectives ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.objectives li {
    margin-bottom: 10px;
    font-size: 1.1em;
}

.objective-section {
    margin-bottom: 20px;
}

.objective-section h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 10px;
}

.objective-section p {
    font-family: 'Lato', sans-serif;
    margin: 10px 0;
}

.Mission,
.Mission1 {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.Mission {
    background-color: #30a5e9;
    color: white;
}

.Mission1 {
    background-color: #ffffff;
    color: #333;
    border: 1px solid #ddd;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-image {
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px;
}

.mission {
    flex: 2;
    padding: 0 20px;
    justify-content: center;
}

.mission h1,
.mission p {
    margin-bottom: 10px;
}

/*h2 {
    display: inline-flex;
    background: linear-gradient(135deg, #3a86dd 0%, #4364F7 100%);
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(16, 82, 121, 0.1);
    font-family: 'Arial', sans-serif;
    background-size: contain;
}*/

/* Responsive styles */
@media (max-width: 768px) {
    .overlay-text {
        font-size: 1.2em;
        padding: 8px 16px;
    }

    .full-width-image {
        max-height: 300px;
    }

    .Mission,
    .Mission1 {
        flex-direction: column;
        align-items: center;
    }

    .image-container,
    .mission {
        flex: 1;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .overlay-text {
        font-size: 1em;
        padding: 6px 12px;
    }

    .full-width-image {
        max-height: 200px;
    }
}