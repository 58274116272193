.blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.blog-container h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}

.blog-posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-post {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s;
}

.blog-post:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
}

.blog-post .date {
    font-family: 'Lato', sans-serif;
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 10px;
}

.blog-post p {
    font-family: 'Lato', sans-serif;
    margin-bottom: 20px;
}

.read-more {
    color: #3e8aad;
    text-decoration: none;
    font-weight: bold;
}

.read-more:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .blog-container {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .blog-post {
        padding: 15px;
    }

    .blog-post h2 {
        font-size: 1.2rem;
    }
}