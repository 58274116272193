/* OurStory.css */


.our-story {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
    border-bottom: 2px solid #3498db;
}

.story-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
}

.story-intro {
    font-size: 1.1rem;
    line-height: 1.6;
}

.story-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.story-section h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.story-section p {
    font-size: 1.1rem;
    line-height: 1.6;
}

.story-section ul {
    list-style-type: disc;
    margin-left: 20px;
}

.get-involved {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.get-involved h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.get-involved p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 0;
}

/* Responsive Adjustments */

@media screen and (max-width: 768px) {
    .section-title {
        font-size: 1.8rem;
    }

    .story-section {
        padding: 15px;
    }

    .get-involved {
        padding: 20px;
    }
}

@media screen and (max-width: 480px) {
    .story-content {
        grid-template-columns: 1fr;
    }
}